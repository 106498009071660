<!--恒牙龋失补构成比统计-->
<template>
  <div>

    <el-card style="margin: 20px;">
      <el-form ref="form" size="small" label-width="70px">
        <el-form-item label="学校：">
          <YeySchool-select ref="schools" v-model="form.schoolId"></YeySchool-select>
        </el-form-item>
        <el-form-item label="年级：">
          <el-input v-model="form.grade"></el-input>
        </el-form-item>
        <el-form-item label="班级：">
          <el-input v-model="form.classRoomNo"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <sex-select v-model="form.sex"></sex-select>
        </el-form-item>
        <el-form-item label="年度：">
          <year-select v-model="form.year"></year-select>
        </el-form-item>
        <el-col :xl="4" :lg="5">
          <el-form-item style="width: 100%;" label="统计类型：" label-width="100px">
            <statistical-type-select v-model="form.groupBy" :clearable="false"  @updateValue="search"></statistical-type-select>
          </el-form-item>
        </el-col>

        <div style="display:flex; margin-left: auto">
          <div>
            <el-button size="small" v-show="$hasPermission('yeyPermanent.list')" type="primary" @click="search">查 询</el-button>
            <el-button size="small" v-show="$hasPermission('yeyPermanent.export')" type="primary" @click="exportExcel">导 出</el-button>
            <el-button size="small" v-show="$hasPermission('yeyPermanent.print')" type="primary" @click="printTable">打印当前页</el-button>
          </div>
        </div>

      </el-form>

    </el-card>
    <el-card style=" margin: 20px">
      <el-table
          id="exportTab"
          :data="formList2"
          v-loading="loading"
          border
          style="width: 100%"
      >
        <el-table-column
            :key="type"
            :label="type"
            prop="_id"
        >
          <template slot-scope="scope">
            <template v-if="form.groupBy === '$sex'">
              <div v-if="scope.row._id === 0">男</div>
              <div v-if="scope.row._id === 1">女</div>
              <div v-if="scope.row._id === '合计'">合计</div>
            </template>
            <template v-else>
              <div>{{ scope.row._id }}</div>
            </template>
          </template>
        </el-table-column>

        <el-table-column
            label="乳龋"
        >
          <el-table-column
              label="d+m+f牙数"
              prop="r046Sum"

          >
          </el-table-column>
          <el-table-column
              label="d牙数"
              prop="r034Sum"
          >
          </el-table-column>
          <el-table-column
              label="乳龋率(%)"
              prop="rate1"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.rate1 |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="m牙数"
              prop="r035Sum"
          >
          </el-table-column>
          <el-table-column
              label="乳龋失率(%)"
              prop="rate2"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.rate2 |percent }}</div>
            </template>
          </el-table-column>
          <el-table-column
              label="f牙数"
              prop="r036Sum"
          >
          </el-table-column>
          <el-table-column
              label="乳龋补率(%)"
              prop="rate3"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.rate3 |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>


      </el-table>

      <div style="color: rgba(80, 155, 248, 1);font-size: 11px; margin-top: 20px">
        备注：恒龋率=D牙数/（D+M+F）牙数*100%；恒龋失=M牙数/（D+M+F）牙数*100%；恒龋补=F牙数/（D+M+F）牙数*100% 。
      </div>
    </el-card>
  </div>
</template>

<script>
import YeySchoolSelect from "@/components/YeySchoolSelect"
import gradeSelect from "@/components/gradeSelect"
import classRoomNoSelect from "@/components/classRoomNoSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"
import * as scriptApi from "@/api/gen/scriptApi";

export default {
  name: "yeyPermanent",
  components: {
    YeySchoolSelect,
    statisticalTypeSelect,
    gradeSelect,
    classRoomNoSelect,
    sexSelect,
    yearSelect,

  },

  data() {
    return {

      form: {
        id: "",
        schoolId: "",
        schoolType: "",
        idNo: "",
        grade: "",
        classRoomNo: "",
        name: "",
        birthDate: "",
        age: "",
        sex: "",
        studentId: "",
        contact: "",
        nationalityCode: "",
        cxType: "",
        checkDate: "",
        year: "",
        result: "",
        advice: "",
        groupBy: ""

      },
      formList: [],

      model: "",

      value: '',


      loading:false,
    }
  },
  computed: {
    type() {
      if (this.form.groupBy === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupBy === '$sex') {
        return '性别'
      }
      if (this.form.groupBy === '$grade') {
        return '年级'
      }
    },
    formList2(){
      var   r046Sum=this._.sumBy(this.formList,'r046Sum')
      var   r034Sum=this._.sumBy(this.formList,'r034Sum')
      var   r035Sum=this._.sumBy(this.formList,'r035Sum')
      var   r036Sum=this._.sumBy(this.formList,'r036Sum')
      this.formList.push({
        "_id":'合计',
        r046Sum,
        r034Sum,
        r035Sum,
        r036Sum,
        rate1: r046Sum?this.divide(r034Sum,r046Sum):0,
        rate2: r046Sum?this.divide(r035Sum,r046Sum):0,
        rate3: r046Sum?this.divide(r036Sum,r046Sum):0
      })
      return this.formList
    }

  },
  mounted() {


  },
  methods: {

    async search() {
      if(!this.form.schoolId){
        this.$message.warning('请选择学校')
        return
      }
      await this.scriptList()

    },


    async scriptList() {
      this.loading = true
      let clone = this._.cloneDeep(this.form)
      if (!clone.schoolId) {
        clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
      }
      clone.queryType = 0;

      if(clone.sex){
        if(clone.sex=='男'){
          clone.sex =0;
        }else{
          clone.sex =1;
        }
      }
      
      let tmp = await scriptApi.execute({name: '全部统计.groovy', params: clone})


      tmp.forEach(item => {
        item.rate1 = this.divide(item.r034Sum, item.r046Sum)
        item.rate2 = this.divide(item.r035Sum, item.r046Sum)
        item.rate3 = this.divide(item.r036Sum, item.r046Sum)

      })

      this.formList = this._.chain(tmp).sortBy(x => parseFloat(x._id)).value()
      this.$sortByGrade(this.formList)
      this.loading = false


    },
    exportExcel() {
      var sex =this.form.sex
      var grade =this.form.grade
      var classRoomNo =this.form.classRoomNo
      var query =[]
      var queryStr =""
      if(sex){
        query.push(sex)
      }
      if(grade){
        query.push(grade)
      }
      if(classRoomNo){
        query.push(classRoomNo)
      }
      if(query.length>0){
        queryStr = query.join("-")
      }
      if(queryStr){
        queryStr=queryStr+"-"
      }
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${queryStr}${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab',queryStr)
    },

    // exportExcel() {
    //   this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    // },


    printTable() {

      this.$print('#exportTab')
    },
    divide(a, b) {
      if (a === 0 || b === 0) {
        return 0
      }
      return (a / b)
    }


  }
}

</script>

<style scoped>
.el-card .el-form {
  display: flex;
  flex-wrap: wrap;

}
/deep/.el-form-item{
  width: 13%;
}

/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
