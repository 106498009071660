<!--龋齿患病情况统计表-->
<template>
  <div>

    <el-card style="margin: 20px;">
      <el-form ref="form"size="small" label-width="70px">
        <el-form-item label="学校：">
          <YeySchool-select ref="schools" v-model="form.schoolId"></YeySchool-select>
        </el-form-item>
        <el-form-item label="年级：">
          <el-input v-model="form.grade"></el-input>
        </el-form-item>
        <el-form-item label="班级：">
          <el-input v-model="form.classRoomNo"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <sex-select v-model="form.sex"></sex-select>
        </el-form-item>
        <el-form-item label="年度：">
          <year-select v-model="form.year"></year-select>
        </el-form-item>
        <el-col :xl="4" :lg="5">
          <el-form-item style="width: 100%;" label="统计类型：" label-width="100px">
            <statistical-type-select v-model="form.groupBy" :clearable="false"  @updateValue="search"></statistical-type-select>
          </el-form-item>
        </el-col>

        <div style="display: flex;margin-left: auto">
          <div>
            <el-button type="primary" size="small" @click="search" v-show="$hasPermission('yeyEyesight.list')">查 询</el-button>
            <el-button type="primary" size="small" @click="exportExcel" v-show="$hasPermission('yeyEyesight.export')">导 出</el-button>
            <el-button type="primary" size="small" @click="printTable" v-show="$hasPermission('yeyEyesight.print')">打印当前页</el-button>
          </div>
        </div>

      </el-form>

    </el-card>
    <el-card style=" margin: 20px">
      <el-table
          id="exportTab"
          :data="formList2"
          v-loading="loading"
          border
          style="width: 100%"
      >
        <el-table-column
            :label="type"
            prop="_id"
            :key="type"
        >
          <template slot-scope="scope">
            <template v-if="form.groupBy === '$sex'">
              <div v-if="scope.row._id === 0">男</div>
              <div v-if="scope.row._id === 1">女</div>
              <div v-if="scope.row._id === '合计'">合计</div>
            </template>
            <template v-else>
              <div>{{ scope.row._id }}</div>
            </template>
          </template>

        </el-table-column>
        <el-table-column
            align="left"
            label="受检人数"
            prop="total"
        >
        </el-table-column>
        <el-table-column
            label="轻度视力不良">
          <el-table-column
              label="人数"
              prop="r066Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
              prop="r066Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r066Rate |percent }}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
            label="中度视力不良">
          <el-table-column
              label="人数"
              prop="r067Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
              prop="r067Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r067Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="重度视力不良">
          <el-table-column
              label="人数"
              prop="r068Sum"
          >
          </el-table-column>
          <el-table-column
              label="率(%)"
              prop="r068Rate"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.r068Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>
        <el-table-column
            label="合计">
          <el-table-column
              label="视力不良人数"
              prop="r073Sum"
          >
          </el-table-column>
          <el-table-column
              label="视力不良率(%)"

          >
            <template slot-scope="scope">
              <div>{{ scope.row.r073Rate |percent }}</div>
            </template>
          </el-table-column>

        </el-table-column>


      </el-table>


      <div style="color: rgba(80, 155, 248, 1);font-size: 11px; margin-top: 20px">
        备注：4.9为轻度视力不良；4.6~4.8为中度视力不良；4.5以及4.5以下为重度视力不良。
      </div>
    </el-card>
  </div>
</template>

<script>

import YeySchoolSelect from "@/components/YeySchoolSelect"
import gradeSelect from "@/components/gradeSelect"
import classRoomNoSelect from "@/components/classRoomNoSelect"
import sexSelect from "@/components/sexSelect"
import yearSelect from "@/components/yearSelect"
import statisticalTypeSelect from "@/components/statisticalTypeSelect"


import * as scriptApi from "@/api/gen/scriptApi"

export default {
  name: "yeyEyesight",
  components: {
    YeySchoolSelect,
    statisticalTypeSelect,
    gradeSelect,
    classRoomNoSelect,
    sexSelect,
    yearSelect,

  },
  data() {
    return {
      form: {
        id: "",
        schoolId: "",
        schoolType: "",
        idNo: "",
        grade: "",
        classRoomNo: "",
        name: "",
        birthDate: "",
        age: "",
        sex: "",
        studentId: "",
        contact: "",
        nationalityCode: "",
        cxType: "",
        checkDate: "",
        year: "",
        result: "",
        advice: "",
        groupBy: ""
      },

      name: '',
      scriptName: '',
      formList: [],


      isEditShow: false,
      isUpdateDialog: false,
      current: {},
      model: "",
      options: "",
      loading: false
    }
  },
  computed: {
    type() {
      if (this.form.groupBy === '$age') {
        return '年龄(岁)'
      }
      if (this.form.groupBy === '$sex') {
        return '性别'
      }
      if (this.form.groupBy === '$grade') {
        return '年级'
      }
    },
    formList2(){
      var  total=this._.sumBy(this.formList,'total')
      var   r066Sum=this._.sumBy(this.formList,'r066Sum')
      var   r067Sum=this._.sumBy(this.formList,'r067Sum')
      var   r068Sum=this._.sumBy(this.formList,'r068Sum')
      var   r073Sum=this._.sumBy(this.formList,'r073Sum')
      this.formList.push({
        "_id":'合计',
        total,r066Sum,r067Sum,r068Sum,r073Sum,
        r066Rate:total?r066Sum/total:0,
        r067Rate:total?r067Sum/total:0,
        r068Rate:total?r068Sum/total:0, 
        r073Rate:total?r073Sum/total:0
      })
      return this.formList
    }

  },
  mounted() {


  },
  methods: {

    async search() {
      if(!this.form.schoolId){
        this.$message.warning('请选择学校')
        return
      }
      await this.scriptList()

    },


    async scriptList() {
      this.loading = true
      let clone = this._.cloneDeep(this.form)
      if (!clone.schoolId) {
        clone.schoolId = this.$refs.schools.getItems().map(item => item.id).join(',')
      }
      clone.queryType = 0;

      if(clone.sex){
        if(clone.sex=='男'){
          clone.sex =0;
        }else{
          clone.sex =1;
        }
      }
      
      this.formList = await scriptApi.execute({name: '全部统计.groovy', params: clone})

      this.formList = this._.chain(this.formList).sortBy(x => parseFloat(x._id)).value()
      this.$sortByGrade(this.formList)
      this.loading = false

    },

    exportExcel() {
      var sex =this.form.sex
      var grade =this.form.grade
      var classRoomNo =this.form.classRoomNo
      var query =[]
      var queryStr =""
      if(sex){
        query.push(sex)
      }
      if(grade){
        query.push(grade)
      }
      if(classRoomNo){
        query.push(classRoomNo)
      }
      if(query.length>0){
        queryStr = query.join("-")
      }
      if(queryStr){
        queryStr=queryStr+"-"
      }
      this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${queryStr}${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab',queryStr)
    },
    // exportExcel() {
    //   this.$exportToExcel(`${this.$store.getters.organizationName}-${this.form.year}-${this.$storage.getStorage('pageName')}-${this.$dateString(this.$now())}.xlsx`, '#exportTab')
    // },

    printTable() {

      this.$print('#exportTab')
    },

  }

}
</script>

<style scoped>
.el-card .el-form {
  display: flex;
  flex-wrap: wrap;

}

/deep/ .el-form-item {
  width: 13%;
}


/deep/ .el-table td,
/deep/ .el-table th {
  text-align: center;
}

/deep/ .el-table td,
/deep/ .el-table th.is-leaf {
  border: 1px solid #EBEEF5 !important;
}

/deep/ .el-table--border th, /deep/ .el-table__fixed-right-patch {
  border: 1px solid #EBEEF5 !important;
}
</style>
