import request from '@/utils/request'

export function create(data) {

  return request({
    url: '/organization/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/organization/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/organization/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/organization/deleteByIds',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/organization/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/organization/getByIds',
    method: 'GET',
    params})
}

   

// export async function  list(params) {
//   if(!params){
//     params ={pageSize:10000}
//   }
//   //查看本地是否有__organizationList为key的缓存
//   var res = StorageWithExpiry.getItem("__organizationList")
//   if(res){
//     return res
//   }
  
//   var re = await request({
//     url: '/organization/list2',
//     method: 'POST',
//     data:params})
//     //将re 存储到浏览器本地中，key为__organizationList，1个小时过期

// // 使用示例：
// // 设置一个10秒后过期的数据
//     StorageWithExpiry.setItem('__organizationList', re, 3600 * 1000);
//     return re
//   // return request({
//   //   url: '/organization/list',
//   //   method: 'GET',
//   //   params})
// }

export function list(params) {
  if(!params){
    params ={pageSize:10000}
  }
  return request({
    url: '/organization/list2',
    method: 'POST',
    data:params})
}
   

export function update(data) {

  return request({
    url: '/organization/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/organization/update/batch',
    method: 'POST',
    data})
}

   

const StorageWithExpiry = {
  // 设置带过期时间的数据
  setItem(key, value, ttl) {
      const item = {
          value: value,
          expiry: ttl ? new Date().getTime() + ttl : null
      };
      localStorage.setItem(key, JSON.stringify(item));
  },

  // 获取数据，如果过期返回null
  getItem(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) return null;

      const item = JSON.parse(itemStr);
      
      // 检查是否设置了过期时间
      if (!item.expiry) return item.value;

      // 检查是否已过期
      if (new Date().getTime() > item.expiry) {
          localStorage.removeItem(key);
          return null;
      }

      return item.value;
  },

  // 删除数据
  removeItem(key) {
      localStorage.removeItem(key);
  },

  // 清空所有数据
  clear() {
      localStorage.clear();
  }
};
